<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Information" subtitle="Keep up to date with the various important details and arrangements for the convention." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no pages found.">

		<app-content-section v-for="page in collection" :key="page.id" route="Convention.Information.Page" :link="(page.type === $constants.page.type.redirect) ? page.redirect : false" :params="{ id: page.slug }" :title="page.name" :description="page.description" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			endpoint: 'convention/information/pages',
			live: 'convention/information/pages'
		}

	}

}

</script>

<style scoped>

</style>